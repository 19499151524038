import * as yup from 'yup';

import { INVENTORY_ROOMS, INVENTORY_FURNITURE_TYPES } from '../constants';

export const inventoryInitialValues = {
  uid: null,
  isDraft: true,
  date: new Date(),
  propertyUid: null,
  leaseUid: null,
  electricalMeter: null, // int
  gasMeter: null, // int
  waterMeter: null, // int
  entryKeys: null, // int
  buildingKeys: null, // int
  gateKeys: null, // int
  garageKeys: null, // int
  postalBoxKeys: null, // int
  rooms: [
    {
      type: 'undefined',
      score: null,
      comment: null,
      equipments: [],
      furniture: [],
      pictures: [],
    },
  ],
  signers: [],
};

export const validationSchema = yup.object().shape({
  uid: yup.string().nullable(),
  isDraft: yup.boolean().required('global.form.errors.required').nullable(),
  date: yup.date().required('global.form.errors.required').nullable(),
  propertyUid: yup.string().required('global.form.errors.required').nullable(),
  leaseUid: yup.string().required('global.form.errors.required').nullable(),
  electricalMeter: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  gasMeter: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  waterMeter: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  entryKeys: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  buildingKeys: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  gateKeys: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  garageKeys: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  postalBoxKeys: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required')
    .nullable(),
  rooms: yup.array().of(
    yup.object().shape({
      type: yup
        .string()
        .oneOf([
          ...INVENTORY_ROOMS.map((room) => room.type),
          null,
        ], 'global.form.errors.required')
        .required('global.form.errors.required')
        .nullable(),
      comment: yup.string().nullable(),
      score: yup
        .number()
        .typeError('global.form.errors.number')
        .moreThan(0, 'global.form.errors.invalid')
        .lessThan(5, 'global.form.errors.invalid')
        .required('global.form.errors.required')
        .nullable(),
      equipments: yup.array().of(yup.object().shape({
        type: yup.string().required('global.form.errors.required').nullable(),
        score: yup
          .number()
          .typeError('global.form.errors.number')
          .moreThan(0, 'global.form.errors.invalid')
          .lessThan(5, 'global.form.errors.invalid')
          .nullable(),
      })),
      furniture: yup.array().of(yup.object().shape({
        type: yup
          .string()
          .oneOf([
            ...INVENTORY_FURNITURE_TYPES.map((furniture) => furniture.value),
            null,
          ], 'global.form.errors.required')
          .required('global.form.errors.required')
          .nullable(),
        score: yup
          .number()
          .typeError('global.form.errors.number')
          .moreThan(0, 'global.form.errors.invalid')
          .lessThan(5, 'global.form.errors.invalid')
          .required('global.form.errors.required')
          .nullable(),
        comment: yup.string().nullable(),
        pictures: yup.array().of(yup.object().shape({
          documentUid: yup.string().required('global.form.errors.required').nullable(),
        })),
      })),
      pictures: yup.array().of(yup.object().shape({
        documentUid: yup.string().required('global.form.errors.required').nullable(),
      })),
    }),
  ),
  signers: yup.array().of(
    yup.object().shape({
      uid: yup.string().required('global.form.errors.required').nullable(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      signed: yup.boolean().nullable(),
      role: yup.string().nullable(),
      wasPresent: yup.boolean().nullable(),
    }),
  ).test(
    'at-least-one-tenant-present',
    'inventory.crud.signers.errorWasPresent',
    (signers) => signers.some((signer) => signer.role === 'tenant' && signer.wasPresent === true),
  ),
});
