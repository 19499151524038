import {
  useMemo, useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import {
  Picto,
  utils,
  RadioGroup,
  Message,
  Button,
  Table,
} from 'ui-library-unlocker';

// components
import IbanChoice from './IbanChoice';
import MaskedField from '../../../components/atoms/MaskedField/MaskedField';
import CopyBtn from '../../../components/atoms/CopyBtn/CopyBtn';
import AddIbanBtn from './AddIbanBtn';
import Modal from '../../../components/molecules/Modal/Modal';
import BankingIdentityForm from '../../../components/organisms/forms/BankingIdentityForm/BankingIdentityForm';
import ConfirmationModal from '../../../components/organisms/ConfirmationModal/ConfirmationModal';

// hooks
import { useAppContext } from '../../../store/context';
import useRoles from '../../../hooks/useRoles';

// utils
import { hideModal, showModal } from '../../../utils/modal';

// services
import {
  changeLeasePaymentMethod,
  getPendingLeasePaymentMethod,
  cancelPaymentMethodChange,
  updateLeasePaymentMethodSDD,
  // delegated
  changeTenantLeasePaymentMethod,
  updateTenantLeasePaymentMethodSDD,
} from '../../../services/lease';

import styles from './LeasePayment.module.scss';

const ADD_BANKING_IDENTITY_MODAL_ID = 'add-banking-identity-modal';
const CONFIRM_MODAL_ID = 'confirm-payment-method-change-modal';
const CONFIRM_CANCELLATION_MODAL_ID = 'confirm-cancellation-payment-method-modal';
const PENDING_REQUEST_STATUS = {
  WAITING_MANDATE_SIGNATURE: 'waiting_mandate_signature',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  DONE: 'done',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
};

function LeasePayment({
  bankingIdentitiesData,
  isLoading,
  error,
  hasBeenRevoked,
  bankInfo,
  bankInfoRefetch,
  lease,
  leaseUID,
  delegatedTenants,
  delegatedTenantUID,
}) {
  const { t } = useTranslation();
  const { context: { user, me } } = useAppContext();
  const { isUserTenant, isUserAdmin } = useRoles();
  const addBankingIdentityRef = useRef(null);
  const addBankIdFormRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [customIdentity, setCustomIdentity] = useState(null);
  const [pendingRequest, setPendingRequest] = useState(null);
  const [selectedDelegatedTenantUID, setSelectedDelegatedTenantUID] = useState(delegatedTenantUID);

  useEffect(() => {
    setSelectedDelegatedTenantUID(delegatedTenantUID);
  }, [delegatedTenantUID]);

  const tableData = useMemo(() => [bankInfo || {}], [bankInfo]);

  const userHasManagementRights = useMemo(() => {
    if (isUserTenant) return false;
    if (isUserAdmin) return true;
    if (!lease?.managerUid || !user?.username || !me?.aclMatrix?.companies) return false;
    if (user.username === lease.managerUid) return true;
    if (me.aclMatrix.companies
      .filter((company) => company.owner || (!company.owner && !company.isRealEstateAgency))
      .some((company) => company.uid === lease.managerUid)) return true;
    return false;
  }, [isUserTenant, user, lease, isUserAdmin, me]);

  const validationSchema = useMemo(() => yup.object().shape({
    paymentMethod: yup
      .string()
      .oneOf([
        'credit_transfer',
        'direct_debit',
      ]),
    selectedIdentity: yup.object().shape({}).nullable().when('paymentMethod', {
      is: 'direct_debit',
      then: yup.object().shape({}).required('global.form.errors.required'),
    }),
  }), []);

  const formik = useFormik({
    initialValues: {
      paymentMethod: 'credit_transfer',
      selectedIdentity: null,
    },
    validationSchema,
    onSubmit: (values) => {
      let dataToSubmit = { paymentMethod: values.paymentMethod };
      if (values.paymentMethod === 'direct_debit') {
        const custom = values.selectedIdentity?.custom;
        dataToSubmit = {
          ...dataToSubmit,
          [custom ? 'customBankingIdentity' : 'bankingIdentity']: custom
            ? values.selectedIdentity
            : values.selectedIdentity?.uid,
        };
      }
      if (bankInfo?.paymentMethod === 'direct_debit' && values.paymentMethod === 'direct_debit') {
        updateSDDMutation.mutate(dataToSubmit);
      } else {
        paymentMethodMutation.mutate(dataToSubmit);
      }
    },
  });

  const paymentMethodMutation = useMutation({
    mutationFn: (data) => {
      if (selectedDelegatedTenantUID) return changeTenantLeasePaymentMethod(selectedDelegatedTenantUID, leaseUID, data);
      return changeLeasePaymentMethod(leaseUID, data);
    },
    onSuccess: ({ data }) => {
      utils.toast.success(t('lease.payment.editSuccess'));
      hideModal(CONFIRM_MODAL_ID);
      if (data?.nextPaymentMethod === 'direct_debit') {
        setPendingRequest(data);
      }
      handleResetForm();
      bankInfoRefetch?.();
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          formik.setErrors(err.response.data?.errors);
          if (
            err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_METHOD_LEASE_TERMINATED_EXCEPTION'
            || err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_INVALID_PAYER_EXCEPTION'
          ) {
            utils.toast.error(t(`lease.payment.formErrors.${err.response.data.message}`));
          } else if (
            err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_METHOD_FORM_EXCEPTION'
            && err.response.data.errors?.['customBankingIdentity.iban']
          ) {
            utils.toast.error(err.response.data.errors['customBankingIdentity.iban']);
          } else {
            utils.toast.error(t('global.form.errors.localGeneric'));
          }
          break;
        }
        case 403: {
          if (err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_METHOD_NOT_A_TENANT_EXCEPTION') {
            utils.toast.error(t(`lease.payment.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.forbidden'));
          }
          break;
        }
        case 409: {
          if (err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_METHOD_SAME_VALUE_EXCEPTION') {
            utils.toast.error(t(`lease.payment.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.localGeneric'));
          }
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const updateSDDMutation = useMutation({
    mutationFn: (data) => {
      if (selectedDelegatedTenantUID) {
        return updateTenantLeasePaymentMethodSDD(selectedDelegatedTenantUID, leaseUID, data);
      }
      return updateLeasePaymentMethodSDD(leaseUID, data);
    },
    onSuccess: ({ data }) => {
      utils.toast.success(t('lease.payment.editSuccess'));
      hideModal(CONFIRM_MODAL_ID);
      if (data?.nextPaymentMethod === 'direct_debit') {
        setPendingRequest(data);
      }
      handleResetForm();
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          formik.setErrors(err.response.data?.errors);
          if (
            err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_SDD_UPDATE_LEASE_TERMINATED_EXCEPTION'
            || err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_SDD_UPDATE_IBAN_NOT_VALID_EXCEPTION'
          ) {
            utils.toast.error(t(`lease.payment.formErrors.${err.response.data.message}`));
          } else if (
            err.response.data?.message === 'PAYMENT_LEASE_PAYMENT_SDD_UPDATE_FORM_EXCEPTION'
            && err.response.data.errors?.['customBankingIdentity.iban']
          ) {
            utils.toast.error(err.response.data.errors['customBankingIdentity.iban']);
          } else {
            utils.toast.error(t('global.form.errors.localGeneric'));
          }
          break;
        }
        case 500: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const cancelPendingRequestMutation = useMutation({
    mutationFn: () => cancelPaymentMethodChange(leaseUID),
    onSuccess: () => {
      utils.toast.success(t('lease.payment.cancelSuccess'));
      hideModal(CONFIRM_CANCELLATION_MODAL_ID);
      setPendingRequest(null);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const pendingRequestQuery = useQuery({
    queryKey: ['pendingLeasePaymentMethod', leaseUID, delegatedTenantUID],
    queryFn: () => getPendingLeasePaymentMethod(leaseUID),
    onSuccess: (data) => {
      setPendingRequest(data?.data);
    },
  });

  const identitesList = useMemo(() => {
    const bankIdData = bankingIdentitiesData || [];
    return [...bankIdData, customIdentity].filter((identity) => identity);
  }, [bankingIdentitiesData, customIdentity]);

  useEffect(() => {
    if (
      formik.values.paymentMethod === 'direct_debit'
      && identitesList?.length > 0
      && !formik.values.selectedIdentity
    ) {
      formik.setFieldValue('selectedIdentity', identitesList[0]);
    }
  }, [identitesList, formik.values.paymentMethod, formik.values.selectedIdentity]);

  const paymentMethods = useMemo(() => [
    {
      id: 'credit_transfer',
      label: t('lease.payment.method.credit_transfer'),
      value: 'credit_transfer',
    },
    {
      id: 'direct_debit',
      label: t('lease.payment.method.direct_debit'),
      value: 'direct_debit',
    },
  ], [t]);

  const handleFormChange = useCallback((key, value) => {
    if (hasBeenRevoked) return;
    formik.setFieldValue(key, value);
  }, [formik, hasBeenRevoked]);

  const ibanOptions = useMemo(() => {
    if (identitesList?.length > 0) {
      return identitesList?.map((identity) => (
        <IbanChoice
          key={identity.uid}
          identity={identity}
          selectedIdentityUID={formik.values.selectedIdentity?.uid}
          handleFormChange={handleFormChange}
          disabled={hasBeenRevoked}
          editModalId={ADD_BANKING_IDENTITY_MODAL_ID}
        />
      ));
    }
    return (
      <Message
        variant="info"
        content={t('lease.payment.noIban')}
      />
    );
  }, [identitesList, formik.values.selectedIdentity, handleFormChange, hasBeenRevoked]);

  const columns = useMemo(() => ([
    {
      header: t('lease.generalInfo.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 200,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod: payMeth } } }) => (
        <div className={styles.tableName}>
          {t(`global.paymentMethods.${payMeth}`)}
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.iban'),
      accessorKey: 'iban',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className={styles.tableName}>
          <MaskedField
            value={original?.iban}
            className={styles.maskedIban}
            formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
          />
          <CopyBtn
            textToCopy={original?.iban}
            htmlTitle={t('global.copy')}
            tooltipText={t('global.copied')}
            size={18}
          />
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.bic'),
      accessorKey: 'bic',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <div className={styles.tableName}>
          {original?.bic}
          &nbsp;
          <CopyBtn
            textToCopy={original?.bic}
            htmlTitle={t('global.copy')}
            tooltipText={t('global.copied')}
            size={18}
          />
        </div>
      ),
    },
    {
      header: t('lease.generalInfo.tenantIban'),
      accessorKey: 'tenantBankingIdentity',
      size: 300,
      enableSorting: false,
      cell: ({ row: { original: { tenantBankingIdentity } } }) => {
        if (!tenantBankingIdentity) {
          return (
            <div className={styles.tableName}>
              -
            </div>
          );
        }
        const tenantIsUser = tenantBankingIdentity.owner === user?.username;
        const bankIdIban = tenantBankingIdentity.iban;
        return (
          <div className={styles.multipleLineColumn}>
            {tenantIsUser ? (
              <Link
                // details page when created
                // to={`/banking-identities/${tenantBankingIdentity.uid}`}
                to="/banking-identities"
                target="_blank"
              >
                {tenantBankingIdentity.label}
                <Picto icon="export-link" width={10} color="var(--color-secondary)" className={styles.externalLink} />
              </Link>
            ) : (
              <span>{`${tenantBankingIdentity.ownerFirstName} ${tenantBankingIdentity.ownerFirstName}`}</span>
            )}
            <div className={styles.tableName}>
              {tenantBankingIdentity.isRedacted ? (
                <span className={styles.maskedIban}>
                  {bankIdIban
                    ?.replace(/\*/g, '•')
                    ?.replace(/(.{4})/g, '$1 ')}
                </span>
              ) : (
                <>
                  <MaskedField
                    value={bankIdIban}
                    className={styles.maskedIban}
                    formatValue={(val) => val.replace(/(.{4})/g, '$1 ')}
                  />
                  <CopyBtn
                    textToCopy={bankIdIban}
                    htmlTitle={t('global.copy')}
                    tooltipText={t('global.copied')}
                    size={18}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
    },
  ]), [t, user]);

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  const handleEditBtn = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleResetForm = useCallback(() => {
    formik.resetForm();
    setIsEditing(false);
    setSelectedDelegatedTenantUID(delegatedTenantUID);
    setTimeout(() => {
      addBankIdFormRef.current?.resetForm();
    }, 1000);
  }, [formik]);

  const handleBankIdentityModalSubmit = useCallback((identity) => {
    const newIdentity = {
      ...identity,
      uid: 'custom-identity',
      ownerFirstName: identity.firstName,
      ownerLastName: identity.lastName,
      custom: true,
    };
    setCustomIdentity(newIdentity);
    formik.setFieldValue('selectedIdentity', newIdentity);
    hideModal(ADD_BANKING_IDENTITY_MODAL_ID);
  }, [formik]);

  const handleSubmitBtn = useCallback(() => {
    if (hasBeenRevoked) return;
    showModal(CONFIRM_MODAL_ID);
  }, [hasBeenRevoked]);

  const handleCloseConfirmModal = useCallback(() => {
    hideModal(CONFIRM_MODAL_ID);
  }, []);

  const pendingRequestMsgVariant = useMemo(() => {
    switch (pendingRequest?.status) {
      case PENDING_REQUEST_STATUS.FAILED:
      case PENDING_REQUEST_STATUS.CANCELLED:
        return 'error';
      default:
        return 'info';
    }
  }, [pendingRequest]);

  const modalConfirmMsg = useMemo(() => {
    if (formik.values.paymentMethod === 'direct_debit') {
      if (delegatedTenantUID) return 'lease.payment.confirmModalMsgSDDLessor';
      return 'lease.payment.confirmModalMsgSDD';
    }
    return 'lease.payment.confirmModalMsgSCT';
  }, [formik.values.paymentMethod, t]);

  const isEditable = useMemo(() => {
    if (hasBeenRevoked) return false;
    if (isUserTenant) return true;
    if (delegatedTenantUID) return true;
    return false;
  }, [hasBeenRevoked, isUserTenant, delegatedTenantUID]);

  const displayPendingRequest = useMemo(() => {
    if (
      pendingRequest
      && pendingRequest.status !== PENDING_REQUEST_STATUS.DONE
      && pendingRequest.nextPaymentMethod === 'direct_debit'
    ) {
      return true;
    }
    return false;
  }, [pendingRequest]);

  const displayCancelRequestBtn = useMemo(() => {
    if (
      displayPendingRequest
      && pendingRequest.status !== PENDING_REQUEST_STATUS.FAILED
      && pendingRequest.status !== PENDING_REQUEST_STATUS.CANCELLED
    ) {
      return userHasManagementRights || isUserTenant;
    }
    return false;
  }, [pendingRequest, userHasManagementRights, isUserTenant, displayPendingRequest]);

  if (error) {
    return (
      <Message
        variant="info"
        className="m-t-50"
        content={t('global.form.errors.global')}
      />
    );
  }

  if (isLoading || pendingRequestQuery.isLoading) {
    return (
      <Picto className="m-t-50" icon="loading" width={30} color="var(--color-secondary)" />
    );
  }

  return (
    <div className="m-t-50">
      {displayPendingRequest && (
        <Message
          variant={pendingRequestMsgVariant}
          className="m-b-30"
          content={(
            <div className={styles.pendingRequest}>
              <p>
                <Trans
                  i18nKey="lease.payment.pendingRequest"
                  values={{
                    month: pendingRequest.month?.toString()?.padStart(2, '0'),
                    year: pendingRequest.year,
                    status: t(`lease.payment.pendingRequestStatus.${pendingRequest.status}`),
                  }}
                />
              </p>
              {displayCancelRequestBtn && (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => showModal(CONFIRM_CANCELLATION_MODAL_ID)}
                  className={utils.cn([styles.cancelPendingRequest, 'm-t-5'])}
                >
                  {t('global.cancel')}
                </Button>
              )}
            </div>
          )}
        />
      )}

      <Table
        fullWidth
        columns={columns}
        data={tableData}
      />

      {isEditable && (
      <>
        {!isEditing ? (
          <Button
            size="medium"
            variant="primary"
            onClick={handleEditBtn}
            className="m-t-30"
          >
            {t('lease.payment.method.title')}
          </Button>
        ) : (
          <>
            <h2 className="m-t-40">{t('lease.payment.method.title')}</h2>

            <div className={utils.cn(['m-t-20', styles.inputGroup])}>
              <div className={styles.largeInput}>
                <RadioGroup
                  name="paymentMethod"
                  options={paymentMethods}
                  value={formik.values.paymentMethod}
                  onChange={(value) => handleFormChange('paymentMethod', value)}
                  disabled={hasBeenRevoked}
                />
              </div>
            </div>

            {formik.values.paymentMethod === 'direct_debit' && (
              <>
                <h2 className="m-t-35">{t('lease.payment.chooseIban')}</h2>

                <div className={utils.cn(['m-t-20', styles.ibanList])}>
                  {ibanOptions}
                  {!customIdentity && (
                    <div
                      className={styles.addIbanBtnWrapper}
                      ref={addBankingIdentityRef}
                    >
                      <AddIbanBtn
                        modalId={ADD_BANKING_IDENTITY_MODAL_ID}
                        parentRef={addBankingIdentityRef}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {isFormError && !hasBeenRevoked
              ? <Message variant="error" className="m-t-35" content={t('global.form.errors.localGeneric')} />
              : null}

            {!hasBeenRevoked && (
              <div className={styles.submit}>
                <Button
                  type="submit"
                  size="large"
                  variant="primary"
                  loading={formik.isSubmitting}
                  onClick={handleSubmitBtn}
                  disabled={formik.isSubmitting || isFormError}
                >
                  {t('global.validate')}
                </Button>
                <p
                  tabIndex={0}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  onKeyDown={handleResetForm}
                  className={styles.cancel}
                  onClick={handleResetForm}
                >
                  {t('global.cancel')}
                </p>
              </div>
            )}
          </>
        )}
        {/* Add banking identity modal */}
        <Modal
          className={styles.modal}
          id={ADD_BANKING_IDENTITY_MODAL_ID}
          title={t('bankingIdentity.crud.addTitle')}
          onClose={() => hideModal(ADD_BANKING_IDENTITY_MODAL_ID)}
        >
          <div className={styles.modalContent}>
            <Message
              variant="info"
              content={t('lease.payment.temporaryIdentity')}
              className={styles.temporaryIdentityMsg}
            />
            <BankingIdentityForm
              onFormSubmitReplace={handleBankIdentityModalSubmit}
              hideCancelBtn
              isEditing={false}
              noLoading
              ref={addBankIdFormRef}
              delegatedTenants={delegatedTenants}
              setSelectedDelegatedTenantUID={setSelectedDelegatedTenantUID}
              delegatedTenantUID={selectedDelegatedTenantUID}
            />
          </div>
        </Modal>
        {/* Confirm modal */}
        <Modal
          id={CONFIRM_MODAL_ID}
          onClose={handleCloseConfirmModal}
          title={t('global.confirmationModal.title')}
          size="small"
        >
          <p className="m-b-30">
            <Trans i18nKey={modalConfirmMsg} />
          </p>
          <div className={styles.modalButtonContainer}>
            <Button
              onClick={formik.handleSubmit}
              size="large"
              label={t('global.confirmationModal.confirm')}
              loading={formik.isSubmitting}
            />
            <Button
              onClick={handleCloseConfirmModal}
              size="large"
              variant="secondary"
              label={t('global.confirmationModal.cancel')}
            />
          </div>
        </Modal>
        {/* Confirm pending request cancellation modal */}
        <ConfirmationModal
          id={CONFIRM_CANCELLATION_MODAL_ID}
          onSubmit={cancelPendingRequestMutation.mutate}
          onCancel={() => hideModal(CONFIRM_CANCELLATION_MODAL_ID)}
          size="small"
          loading={cancelPendingRequestMutation.isLoading}
        />
      </>
      )}
    </div>
  );
}

LeasePayment.propTypes = {
  bankingIdentitiesData: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string.isRequired,
    iban: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })),
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  hasBeenRevoked: PropTypes.bool,
  bankInfo: PropTypes.shape({
    paymentMethod: PropTypes.string,
    iban: PropTypes.string,
    bic: PropTypes.string,
    tenantBankingIdentity: PropTypes.shape({
      uid: PropTypes.string,
      iban: PropTypes.string,
      owner: PropTypes.string,
      ownerFirstName: PropTypes.string,
      ownerLastName: PropTypes.string,
      isRedacted: PropTypes.bool,
    }),
  }),
  bankInfoRefetch: PropTypes.func,
  lease: PropTypes.shape({
    managerUid: PropTypes.string,
  }),
  leaseUID: PropTypes.string.isRequired,
  delegatedTenantUID: PropTypes.string,
  delegatedTenants: PropTypes.arrayOf(PropTypes.shape({})),
};

LeasePayment.defaultProps = {
  bankingIdentitiesData: [],
  isLoading: true,
  error: false,
  hasBeenRevoked: false,
  bankInfo: {},
  bankInfoRefetch: null,
  lease: {},
  delegatedTenantUID: null,
  delegatedTenants: [],
};

export default LeasePayment;
